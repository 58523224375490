import React from "react";

interface Props {
  size?: number;
  color?: string;
}

export const CheckIcon: React.FC<Props> = ({
  size = 48,
  color = "#079455",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 58 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9997 28.4997L24.9997 36.4997L40.9997 20.4997M55.6663 28.4997C55.6663 43.2273 43.7273 55.1663 28.9997 55.1663C14.2721 55.1663 2.33301 43.2273 2.33301 28.4997C2.33301 13.7721 14.2721 1.83301 28.9997 1.83301C43.7273 1.83301 55.6663 13.7721 55.6663 28.4997Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
