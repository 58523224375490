import React, { useState, useEffect, useRef } from "react";
import {
  type ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "firebase";
import tw from "twin.macro";
import { Button } from "@components/commons/Button";
import { InputField } from "@components/commons/InputField";
import { toE164 } from "@utils/formatters";
import OPTInput from "@components/OPTInput";
import {
  emailPattern,
  isPhoneNumberComplete,
  validateInput,
} from "@utils/validations";
import { CheckIcon } from "@assets/CheckIcon";
const Paragraph = tw.p`text-[20px] font-normal mb-[20px]`;
const ErrorMsg = tw.p`text-red-500 mt-2`;
const ActionContainer = tw.div`flex flex-col sm:flex-row sm:gap-3`;
const AUTH_URL = import.meta.env.VITE_AUTH_URL;

declare const grecaptcha: any;

type Props = {
  onChange: any;
  clearErrors: any;
  error: any;
  name: string;
  value: string;
  verified: boolean;
  setVerified: any;
};

const PhoneOrEmailAuth: React.FC<Props> = ({
  onChange,
  clearErrors,
  error,
  name,
  verified,
  setVerified,
  value,
}) => {
  const [phoneVerifiedError, setPhoneVerifiedError] = useState(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null);
  const [verifying, setVerifying] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);

  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);
  const recaptchaWidgetIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (recaptchaVerifierRef.current) return;

    recaptchaVerifierRef.current = new RecaptchaVerifier(
      auth,
      "captcha-placeholder",
      {
        size: "invisible",
      },
    );

    recaptchaVerifierRef.current.render().then((widgetId) => {
      recaptchaWidgetIdRef.current = widgetId;
    });
  }, []);

  const onSignInSubmit = () => {
    setSendingOtp(true);
    const appVerifier = recaptchaVerifierRef.current!;
    const phoneToFirebase = toE164(value);

    signInWithPhoneNumber(auth, phoneToFirebase, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
      })
      .catch((error) => {
        alert(
          `Error during signInWithPhoneNumber:\n\n${error.code}\n\n${error.message}`,
        );
      })
      .finally(() => {
        setSendingOtp(false);
      });
  };

  const onVerifyCodeSubmit = (verificationCode: string) => {
    setVerifying(true);

    confirmationResult!
      .confirm(verificationCode)
      .then(async (result) => {
        const idToken = await result.user?.getIdToken();
        await fetch(`${AUTH_URL}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
          credentials: "include",
        });
        setConfirmationResult(null);
        setVerified(true);
        setPhoneVerifiedError(false);
        clearErrors(name);
      })
      .catch(() => {
        setVerified(false);
        setPhoneVerifiedError(true);
      })
      .finally(() => {
        setVerifying(false);
      });
  };

  const onCancelClick = () => {
    setConfirmationResult(null);
    setPhoneVerifiedError(false);
    if (grecaptcha && recaptchaWidgetIdRef.current !== null) {
      grecaptcha.reset(recaptchaWidgetIdRef.current);
    }
  };

  const { type, value: inputValue } = validateInput(value);
  const isComplete =
    type === "phone" && isPhoneNumberComplete(inputValue) && !verified;

  return (
    <div>
      <div id="captcha-placeholder" className="hidden" />
      {!confirmationResult && (
        <>
          <Paragraph>
            you'll need your REALLY account to activate your line, manage
            billing, and access future updates.
          </Paragraph>

          <InputField
            label="phone or email"
            name={name}
            placeholder="(555) 555-3434 or you@company.com"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { type, value } = validateInput(e.target.value);
              if (type === "email" && emailPattern.test(value)) {
                setVerified(true);
              } else {
                setVerified(false);
              }
              onChange({ type, value });
            }}
            value={value}
            error={error}
          />
          {verified && type === "phone" && (
            <div className="flex items-center gap-2">
              <span>congrats, your phone number is verified </span>
              <CheckIcon size={24} />
            </div>
          )}
          {isComplete && (
            <>
              <p>we'll send you a verification code</p>
              <Button
                title="send verification code"
                loading={sendingOtp}
                type="active"
                onClick={onSignInSubmit}
                className="font-[200] h-[65px] md:w-[350px] w-full mt-[20px] text-white"
              />
            </>
          )}
        </>
      )}
      {confirmationResult && (
        <>
          <Paragraph>
            verify your phone number, please enter verification code we sent to{" "}
            {value}
          </Paragraph>
          <OPTInput
            error={phoneVerifiedError}
            onChange={(val: string) => {
              setPhoneVerifiedError(false);
              if (val.length === 6) onVerifyCodeSubmit(val);
            }}
          />
          {phoneVerifiedError && (
            <ErrorMsg>incorrect verification code, please try again.</ErrorMsg>
          )}
          <ActionContainer>
            <Button
              loading={verifying}
              title="verify"
              type="active"
              onClick={onVerifyCodeSubmit}
              className="font-[200] h-[65px] lg:w-[258px] md:w-[238px] w-full mt-[20px]"
            />
            <Button
              title="cancel"
              type="outline"
              onClick={onCancelClick}
              className="font-[200] h-[65px] lg:w-[258px] md:w-[238px] w-full mt-[20px]"
            />
          </ActionContainer>
        </>
      )}
    </div>
  );
};

export default PhoneOrEmailAuth;
